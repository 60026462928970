import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'

const Stats = ({ className, stats }) => {
	return (
		<Wrap className={className}>
			<StatsContainer>
				{stats?.map(stat => (
					<Stat key={stat.text} >
						<Number className='large'>
							{stat?.stat}
						</Number>
						<StatText>
							{stat?.text}
						</StatText>
					</Stat>
				))}
			</StatsContainer>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: 59px 0;
	background: var(--pinkGradient);
`
const StatsContainer = styled.div`
	grid-column: span 12;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 27px;
	max-width: 850px;
	width: 100%;
	margin: 0 auto;
	${mobile}{
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
		grid-column: span 12;
	}
`
const Stat = styled.div`
	background-color: var(--white);
	border-radius: 15px;
	padding: 40px 20px;
	${mobile}{
		padding: 48px 20px 52px;
	}
`
const Number = styled.div`
	text-align: center;
	margin-bottom: 33px;
	${mobile}{
		margin-bottom: 18px;
	}
`
const StatText = styled.div`
	text-align: center;
	${mobile}{
		max-width: 200px;
		margin: 0 auto;
	}
`

Stats.propTypes = {
	className: PropTypes.string,
	stats: PropTypes.array,
}

export default Stats