import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import RichText from '~components/RichText'
import Seo from '~components/Seo'
import Section from '~components/Section'
import DataViewer from '~utils/DataViewer'
import Image from '~components/Image'
import { mobile, tablet, desktop, HeaderImageDesk, HeaderImageMobile } from '~styles/global'
import PostTile from '~components/PostTile'
import PostTileLarge from '~components/PostTileLarge'
import ContactFormModule from '~components/ContactFormModule'
import Stats from '~components/Stats'

const StoriesIndex = ({ data }) => {
	const { page } = data
	return(
		<>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<HeaderImageDesk image={page.headerImage} cover hideMobile={page.headerImageMobile}/>
			<HeaderImageMobile image={page.headerImageMobile} cover/>
			<IntroductionSection>
				<Heading>
					{page.heading}
				</Heading>
				<Introduction>
					<RichText content={page.introduction}/>
				</Introduction>
			</IntroductionSection>
			<Stats stats={page.stats}/>
			<StoriesSection>
				<Stories>
					<MainStory content={page.stories[0]}/>
					{page.stories?.slice(1)?.map(story => (
						<StoryTile content={story} key={story?.slug?.current} />
					))}
				</Stories>
			</StoriesSection>
			<ContactFormModule content={{backgroundGradient : 'green'}}/>
			<DataViewer data={page} name="page"/>
		</>
	)
}

const IntroductionSection = styled(Section)`
	margin: 60px 0;
	${mobile}{
		margin: 27px 0 50px;
	}
`
const Heading = styled.h1`
	grid-column: 3/5;
	${tablet}{
		grid-column: 2/5;
	}
	${mobile}{
		grid-column: span 12;
		margin-bottom: 42px;
	}
`
const Introduction = styled.div`
	grid-column: 6/11;
	${tablet}{
		grid-column: 6/12;
	}
	${mobile}{
		grid-column: span 12;
	}
`
const StoriesSection = styled(Section)`
	background-color: var(--grey);
	padding: 60px 0; 
`
const Stories = styled.div`
	grid-column: span 12;
	max-width: 850px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 30px;
`
const MainStory = styled(PostTileLarge)`
	grid-column: span 2;
	margin-bottom: 30px;
`
const StoryTile = styled(PostTile)`
	grid-column: span 1;
	margin-bottom: 30px;
	${mobile}{
		grid-column: span 2;
	}
`

StoriesIndex.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query StoriesIndexQuery($slug: String) {
    page: sanityStoriesIndex(slug: {current: {eq: $slug}}) {
      title
			headerImage {
				...imageWithAlt
			}
			headerImageMobile {
				...imageWithAlt
			}
			heading
			introduction: _rawIntroduction
			stats {
				stat
				text
			}
			stories {
				...storyThumb
			}
			seo{
				...seo
			}
    }
  }
`

export default StoriesIndex